import { ConfirmationModalProps } from '../../../shared/modal.interface';
import { Button, Dialog, DialogBody, Icon, Paragraph, Token } from '@squantumengine/horizon';
import { WarningIcon } from '../../../assets/icons';

export default function ConfirmationModal({
  title,
  message,
  modalState,
  cancelText,
  okText,
  isDanger = false,
  loadingConfirmation,
  confirmationCloseCancel,
  confirmationCloseOk,
  closable = false,
  isWarning
}: Readonly<ConfirmationModalProps>) {
  return (
    <Dialog
      className="w-[500px]"
      open={modalState}
      onClose={() => {}}
      hideCloseBtn
      closeOnClickOutside={closable}>
      <DialogBody className="relative flex flex-col">
        <div className="flex gap-4">
          {isWarning ? (
            <div className="mt-2 flex h-fit w-fit items-center justify-center rounded-full bg-orange-100 p-2 text-center">
              <WarningIcon />
            </div>
          ) : (
            <div className="h-fit w-fit rounded-full bg-green-500/20 p-2">
              <div
                className={`${
                  isDanger ? 'bg-red-500' : 'bg-green-500'
                } flex h-6 w-6 shrink-0 items-center justify-center rounded-full p-1`}>
                <Icon
                  name={isDanger ? 'exclamation-circle' : 'check-circle'}
                  color={Token.COLORS.neutral[0]}
                  className="shrink-0"
                  size="xl"
                />
              </div>
            </div>
          )}
          <div className="flex flex-1 flex-col gap-2 pt-2">
            <Paragraph className="text-xl font-semibold">{title}</Paragraph>
            <Paragraph className="text-sm empty:hidden">{message}</Paragraph>
          </div>
        </div>
        <div className={`-mx-6 flex translate-y-5 justify-end gap-3 rounded-b-lg px-6 pb-5 pt-4`}>
          {cancelText && (
            <Button onClick={confirmationCloseCancel} variant={isWarning ? 'secondary' : 'text'}>
              {cancelText}
            </Button>
          )}
          <Button
            variant="primary"
            loading={loadingConfirmation}
            onClick={confirmationCloseOk}
            className="shadow-none">
            {okText}
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
}
