import { ErrorBoundary as ErrorBoundaryElement } from 'react-error-boundary';
import NotFoundImg from '../../assets/image/Photo-min.png';
import { Button } from '@squantumengine/horizon';

function ErrorFallbackComponent({ error, resetErrorBoundary }: any) {
  const handleClickReset = () => {
    resetErrorBoundary();
    window.location.reload();
  };
  return (
    <div data-testid="error-boundaries" className="flex h-screen flex-col justify-center gap-8">
      <div className="flex w-full flex-col items-center gap-6">
        <img src={NotFoundImg} alt="not-found" className="md:w-[360px] w-[300px]" />
        <p className="my-0 max-w-[700px] text-center text-2xl font-bold text-black-90">
          Something went wrong!
        </p>
        <p className="my-0 max-w-[700px] text-center text-sm text-black-90">{error.message}</p>
        <Button onClick={handleClickReset}>Coba lagi</Button>
      </div>
    </div>
  );
}

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <ErrorBoundaryElement fallbackRender={ErrorFallbackComponent}>{children}</ErrorBoundaryElement>
  );
};

export default ErrorBoundary;
