import { Button, Dialog, DialogBody, DialogHeader, Paragraph } from '@squantumengine/horizon';

interface CommentBoxDeleteConfirmationProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function CommentBoxDeleteConfirmation(props: CommentBoxDeleteConfirmationProps) {
  const { open, onCancel, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogHeader>
        <Paragraph className="text-xl font-semibold">Hapus komentar?</Paragraph>
      </DialogHeader>
      <DialogBody className="pt-0">
        <div className="flex flex-col gap-4">
          <Paragraph>
            Jika dihapus, komentar yang telah Anda berikan tidak akan tersimpan.{' '}
          </Paragraph>
          <div className="flex items-center justify-end gap-2">
            <Button variant="secondary" onClick={onCancel}>
              Batalkan
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              Hapus
            </Button>
          </div>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default CommentBoxDeleteConfirmation;
