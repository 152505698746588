import { Divider } from 'antd';
import { ReactComponent as Clone } from '../../../assets/icons/clone.svg';
import { Button } from '@squantumengine/horizon';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
function CodeCard({ curl }: any) {
  const { copy } = useCopyToClipboard();

  return (
    <div className="flex w-full rounded-lg bg-black-90">
      <div className="flex w-full shrink-0 flex-grow basis-0 flex-col items-start justify-center gap-2 p-4">
        <Button
          onClick={() => copy(curl ?? '')}
          size="sm"
          variant="text"
          className="px-1 text-green-500 hover:bg-transparent active:bg-slate-400">
          <Clone />{' '}
          <span className="font-normal leading-4 underline underline-offset-4">Copy Code</span>
        </Button>
        <Divider className="m-0 bg-grey-100" />
        <p className="m-0 whitespace-pre-line font-Courier text-white-background">{curl}</p>
      </div>
    </div>
  );
}

export default CodeCard;
