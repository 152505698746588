import { Button, Divider, Icon, Paragraph, Token } from '@squantumengine/horizon';
import Copy from '../copy';
import CommentLinesIcon from '../../assets/icons/comment-lines';
import { ReactNode, useState } from 'react';
import { useAppConfig } from '../../hooks/useAuth';
import { useCampaignDetailContext } from '../../pages/campaign-detail/context/campaign-detail-context';
import { IMetaBroadcast } from '../campaign-details/detail-broadcast';
import { IMetaContent } from '../campaign-details/content-info.interface';
import useCampaignParams from '../../hooks/useCampaignParams';
import CopyInstructionModal from '../modal/copy-instruction-modal';
import useModal from '../../hooks/useModal';
import InfoChannelModal from '../modal/info-channel-modal';
import { IconWrapper } from '../../pages/campaign-detail/page-header/page-header-actions';
import { ReactComponent as Clone } from '../../assets/icons/clone.svg';
import { useNavigate } from 'react-router-dom';
import { CampaignTypeEnum } from '../../shared/automated-campaign.interface';
import { getChannelTypeLabel } from '../../utils/channel';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

export interface IChannelActionComponent {
  metaContent?: { broadcast?: Partial<IMetaBroadcast>; content: Partial<IMetaContent> };
  isAutomatic?: boolean;
  isMarketing?: boolean;
  curl?: string;
}

const ChannelActionComponent = (props: IChannelActionComponent) => {
  const { metaContent, isAutomatic, isMarketing, curl } = props;
  const { visible, openModal, closeModal } = useModal();
  const { visible: visibleInfo, openModal: openModalInfo, closeModal: closeModalInfo } = useModal();
  const navigate = useNavigate();

  const { channelId, channelType } = useCampaignParams();
  const { handleOpenDrawer } = useCampaignDetailContext();
  const { copy } = useCopyToClipboard();

  const { isCheckerMaker } = useAppConfig();
  const connectedChannelList = metaContent?.content?.channelList || [];
  const campaignId = metaContent?.content?.campaignId || '';
  const campaignType = isAutomatic ? CampaignTypeEnum.AUTOMATED : CampaignTypeEnum.MANUAL;
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<ReactNode>('');

  const handleInfoChannelContent = (title: string, content: ReactNode) => {
    setTitle(title);
    setContent(content);
    openModalInfo();
  };

  const handleClickSee = (url: string) => {
    closeModalInfo();
    navigate(url);
  };

  const handleInfoChannelModal = (type: string) => {
    if (type === 'info') {
      const content = (
        <div className="flex w-full flex-col gap-3">
          <div className="flex border-collapse flex-col gap-1">
            <Paragraph weight="semibold">Judul konten</Paragraph>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.title}
            </Paragraph>
          </div>
          <Divider className="mx-0 w-full bg-neutral-600 p-0" />
          <div className="flex flex-col gap-1">
            <Paragraph weight="semibold">Deskripsi</Paragraph>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.description || '-'}
            </Paragraph>
          </div>
          <Divider className="m-0 w-full bg-neutral-100" />
          <div className="flex flex-col gap-1">
            <Paragraph weight="semibold">Nomor pengirim</Paragraph>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.senderNumber}
            </Paragraph>
          </div>
          <Divider className="m-0 w-full p-0" />
          <div className="flex flex-col gap-1">
            <Paragraph weight="semibold">Kategori</Paragraph>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.category}
            </Paragraph>
          </div>
        </div>
      );
      handleInfoChannelContent('Info konten', content);
    } else {
      const content = (
        <div className="flex w-full flex-col gap-3">
          <div className="flex flex-col gap-1">
            <div className="hz-flex hz-flex-row hz-justify-between hz-items-center">
              <Paragraph weight="semibold">Judul konten</Paragraph>
              <Button variant="text" size="sm" onClick={() => copy(campaignId)}>
                <IconWrapper>
                  <Clone />
                </IconWrapper>
                Copy Campaign ID
              </Button>
            </div>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.title}
            </Paragraph>
          </div>
          <Divider className="m-0 p-0" />
          <div className="flex flex-col gap-1">
            <Paragraph weight="semibold">Deskripsi</Paragraph>
            <Paragraph size="s" className="text-neutral-600">
              {metaContent?.content.description}
            </Paragraph>
          </div>
          <Divider className="m-0 p-0" />
          <div className="flex flex-col gap-1">
            <Paragraph weight="semibold">
              Konten Terhubung ({connectedChannelList.length})
            </Paragraph>
            {connectedChannelList.map((item, index) => (
              <div
                key={index}
                className="flex flex-row items-center justify-between gap-4 rounded-md p-2 shadow-sm">
                <div className="flex flex-col gap-1">
                  <Paragraph weight="semibold">{item.name}</Paragraph>
                  <Paragraph size="s" className="text-neutral-600">
                    {getChannelTypeLabel(item.channel.key, 'Email')}
                  </Paragraph>
                </div>
                <Button
                  variant="text"
                  size="sm"
                  onClick={() =>
                    handleClickSee(`/campaign/${campaignType}/${item.channel.key}/${item.id}`)
                  }>
                  Lihat
                </Button>
              </div>
            ))}
          </div>
        </div>
      );
      handleInfoChannelContent('Terhubung dalam Campaign', content);
    }
  };

  return (
    <div className="flex w-full rounded-lg px-6 py-5 shadow-blurshadow">
      <div className="flex items-center">
        {campaignId && (
          <>
            <Button
              variant="text"
              className="h-auto rounded px-2"
              onClick={() => handleInfoChannelModal('connected')}>
              <Icon name="bullhorn" color={Token.COLORS.blue[500]} />
              <div className="ml-2 flex flex-col gap-1">
                <span className="self-start font-medium leading-4">Terhubung dalam Campaign</span>
                <span className="self-start text-sm font-medium leading-4 text-neutral-500">
                  {`Total ${connectedChannelList.length} konten`}
                </span>
              </div>
            </Button>
            <div className="mx-2 h-1/2 w-[1px] bg-neutral-200" />
          </>
        )}
        <Button
          variant="text"
          className="h-auto rounded px-2 py-1"
          onClick={() => handleInfoChannelModal('info')}>
          <Icon name="info-circle" color={Token.COLORS.blue[500]} />
          <span className="ml-2 font-medium leading-4">Info Konten</span>
        </Button>
        <div className="mx-2 h-1/2 w-[1px] bg-neutral-200" />
        {isAutomatic && (
          <>
            <Button variant="text" className="h-auto rounded px-2 py-1" onClick={openModal}>
              <Icon name="code" color={Token.COLORS.blue[500]} />
              <span className="ml-2 font-medium leading-4">Instruksi copy code</span>
            </Button>
            <div className="mx-2 h-1/2 w-[1px] bg-neutral-200" />
          </>
        )}
        <Copy title="Copy Channel ID" value={channelId ?? ''} className="h-auto rounded px-2" />
        {isMarketing && isCheckerMaker && (
          <>
            <div className="mx-2 h-1/2 w-[1px] bg-neutral-200" />
            <Button
              data-testid="open-cm-drawer-button"
              variant="text"
              className="h-auto rounded px-2 py-1"
              onClick={handleOpenDrawer}>
              <CommentLinesIcon />
              <span className="font-medium leading-4">Lihat Riwayat Komentar</span>
            </Button>
          </>
        )}
      </div>
      <CopyInstructionModal
        open={visible}
        onCancel={closeModal}
        isCampaign={!!campaignId}
        curl={curl}
        channelType={channelType}
      />
      <InfoChannelModal
        visible={visibleInfo}
        onClose={closeModalInfo}
        title={title}
        content={content}
      />
    </div>
  );
};

export default ChannelActionComponent;
