import { Popover } from 'antd';
import CommentBoxComponentHeader from './comment-box-header';
import CommentBoxComponentContent from './comment-box-content';
import CommentBoxComponentTrigger from './comment-box-trigger';
import CheckerBubbleIcon from '../../../assets/icons/checker-bubble';
import { CommentAnchorType, CommentInterface } from '../checker.interface';
import useCommentBoxComponent from './useCommentBoxComponent';
import { Dispatch, SetStateAction } from 'react';
import CommentBoxDeleteConfirmation from './comment-box-delete-confirmation';

export interface CommentBoxComponentProps {
  rect: DOMRect;
  anchor: string;
  element?: Element | null;
  comment?: CommentInterface;
  number?: number;
  onOpenChange: Dispatch<SetStateAction<CommentAnchorType | null>>;
  addComment: (comment: CommentInterface) => void;
  removeComment: (anchor: CommentAnchorType, id: string) => void;
  updateComment: (comment: CommentInterface) => void;
  cleanedAnchor?: () => void;
  resolveComment?: (id: string) => void;
}

const CommentBoxComponent = (props: CommentBoxComponentProps) => {
  const { rect, comment, number, anchor } = props;
  const {
    isPopoverOpen,
    isEditContent,
    isHoverOpen,
    isDelete,
    onHover,
    onLeave,
    onSubmit,
    handleClickTrigger,
    onCancel,
    onPopoverOpenChange,
    onEdit,
    onDelete,
    onConfirmDelete,
    onResolved,
    isResolved,
    isCanEditDelete
  } = useCommentBoxComponent(props);

  return (
    <>
      <div
        className="absolute"
        data-testid="checker-maker-comment-bubble"
        style={{
          top: rect.y,
          left: rect.left + rect.width - 10
        }}>
        <Popover
          open={isPopoverOpen}
          onOpenChange={onPopoverOpenChange}
          trigger="click"
          arrow={false}
          overlayClassName="checker-popover"
          placement="rightTop"
          zIndex={1029}
          title={
            <CommentBoxComponentHeader
              isOpen={!isHoverOpen}
              isEdit={isEditContent}
              onClickDelete={() => onDelete(anchor)}
              onClickEdit={onEdit}
              onResolved={onResolved}
              isResolved={isResolved}
              isCanEditDelete={isCanEditDelete}
            />
          }
          content={
            <CommentBoxComponentContent
              comment={comment}
              isEdit={isEditContent}
              onCancel={onCancel}
              onSubmit={onSubmit}
            />
          }>
          <div
            onMouseOver={onHover}
            onClick={handleClickTrigger}
            onMouseOut={onLeave}
            className="checker-bubble"
            data-testid="cheker-maker-comment-popover-trigger">
            <CommentBoxComponentTrigger comment={comment} number={number} />
            <CheckerBubbleIcon className="absolute inset-0" />
          </div>
        </Popover>
      </div>
      <CommentBoxDeleteConfirmation
        open={isDelete}
        onCancel={() => onDelete()}
        onConfirm={onConfirmDelete}
      />
    </>
  );
};

export default CommentBoxComponent;
