import Button from '../../../buttons';
import { Dayjs } from 'dayjs';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@squantumengine/horizon';

function UneditableTemplateModal({
  open,
  editableDate,
  onClose
}: Readonly<{
  open: boolean;
  editableDate: Dayjs;
  onClose: () => void;
}>) {
  return (
    <Dialog open={open} onClose={onClose} className="w-[521px]">
      <DialogHeader>Tidak dapat mengedit konten</DialogHeader>
      <DialogBody className="flex flex-col gap-4">
        <p className="m-0 text-base">
          Anda baru dapat mengedit konten ini pada {editableDate.format('DD MMMM YYYY')} pukul{' '}
          {editableDate.format('hh:mm')}. Setelah itu, konten Anda akan dikirim dan ditinjau ulang
          oleh Meta.
        </p>
      </DialogBody>
      <DialogFooter className="w-full">
        <Button className="w-full" type="primary" onClick={onClose}>
          Mengerti
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default UneditableTemplateModal;
