import Button from '../../../buttons';
import { Dialog, DialogBody, DialogFooter, DialogHeader } from '@squantumengine/horizon';

function ModalEdit({
  open,
  onCancel,
  onClickContinue
}: Readonly<{
  open: boolean;
  onCancel: () => void;
  onClickContinue: () => void;
}>) {
  return (
    <Dialog open={open} onClose={onCancel} className="w-[521px]">
      <DialogHeader>Lanjutkan Edit?</DialogHeader>
      <DialogBody className="flex flex-col gap-4">
        <p className="m-0 text-base">
          Apakah yakin ingin melakukan edit pada konten campaign? jika klik Lanjutkan maka konten
          campaign ini akan mulai dari ulang untuk dikirimkan ke Meta
        </p>
      </DialogBody>
      <DialogFooter className="flex w-full flex-col gap-4">
        <Button className="w-full" type="primary" onClick={onClickContinue}>
          Lanjutkan
        </Button>
        <Button className="w-full" onClick={onCancel}>
          Batalkan
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default ModalEdit;
